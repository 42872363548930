import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { BookingSharedModule } from '@mya/booking-shared';
import { environment as env } from '../environments/environment';
import { APP_CONFIG } from '@mya/configuration';
import { ReactiveFormsModule } from '@angular/forms';
import {
  NgbDateParserFormatter,
  NgbDatepickerModule,
} from '@ng-bootstrap/ng-bootstrap';
import { APP_BASE_HREF, CommonModule, DatePipe } from '@angular/common';
import { NgbDateFormatter } from '@mya/models';
import { IMaskModule } from 'angular-imask';
import { LayoutModule } from './layout/layout.module';
import { ConsultationBookedComponent } from './components/pages/consultation-booked/consultation-booked.component';
import { DateCheckerComponent } from './components/pages/date-checker/date-checker.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';

@NgModule({
  declarations: [
    AppComponent,
    ConsultationBookedComponent,
    DateCheckerComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BookingSharedModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    IMaskModule,
    LayoutModule,
    RouterModule.forRoot(appRoutes, { initialNavigation: 'enabledBlocking' }),
  ],
  providers: [
    {
      provide: APP_CONFIG,
      useValue: env,
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateFormatter,
    },
    { provide: APP_BASE_HREF, useValue: '/wwym' },
    DatePipe,
    PendingChangesGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
