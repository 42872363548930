import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Account, AccountStatus, Concerns, ExternalUser, FindsUsSources, MentoringProgram, MentoringSystem, ReferenceDataTypes, ReferenceItem, RegisterAccountRequest, SmartCode, SmartTypes, adjustDateByTimeZone, sameEmailValidator } from '@mya/models';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SmartTypesService } from '../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { ConsultationBooking, Steps, ConsultationBookingLookup } from '../../../common/constants/steps.constant';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingAppointmentSlot, BookingDatetime, BookingService } from '@mya/booking-shared';
import * as moment from 'moment';
import { AccountService } from '../../../services/account.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { HttpStatusCode } from '@angular/common/http';
import { DateTimeService } from '../../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';
import { ViewportScroller } from '@angular/common';
declare const toastr: any;

@Component({
  selector: 'mya-consultation-booking-wizard',
  templateUrl: './consultation-booking-wizard.component.html',
  styleUrls: ['./consultation-booking-wizard.component.scss']
})
export class ConsultationBookingWizardComponent implements OnInit, OnDestroy {
  linkedAccount: Account | null = null;
  name: string | null = null;
  email: string | null = null;
  url: string | null = null;
  userTypes: Record<string, SmartCode> = {};
  validSteps: Record<number, boolean> = {};
  overriddenDate: Date | null = null;
  currentPage = Steps.ConsultationBooking.AppointmentDate;
  countryCodeLookup: SmartCode[] = []
  userTypeLookup: SmartCode[] = [];
  meetingDuration = 0;
  preSelectAppointment: BookingAppointmentSlot | null = null;
  showAppointmentDateStep = true;
  bookingAppointmentInitialized = false;
  timeZones: ReferenceItem[] = [];
  manualDeactivate = false;
  subscriptions: Subs[] = [];

  get Step() {
    return Steps.ConsultationBooking;
  }

  get ConsultationBookingLookup() {
    return ConsultationBookingLookup;
  }

  get appointmentSlot() {
    return this.form.controls[ConsultationBooking.AppointmentDate].controls.appointment.value;
  }

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  form = this.formBuilder.group({
    [ConsultationBooking.AppointmentDate]: this.formBuilder.group({
      appointment: [null as BookingAppointmentSlot | null, [Validators.required]],
    }),
    [ConsultationBooking.MenteeDetails]: this.formBuilder.group({
      parent: this.formBuilder.group({
        firstName: [null as string | null, [Validators.required, Validators.minLength(2)]],
        lastName: [null as string | null, [Validators.required, Validators.minLength(2)]],
        email: [null as string | null, [Validators.required, Validators.email]],
        timeZone: [null as string | null, [Validators.required]],
        countryCode: [null as string | null, [Validators.required]],
        phoneNumber: [null as string | null, [Validators.required]],
        receiveTexts: [true, [Validators.required]],
      }),
      mentee: this.formBuilder.group({
        firstName: [null as string | null, [Validators.required, Validators.minLength(2)]],
        lastName: ['', [Validators.minLength(2)]],
        email: [null as string | null, [Validators.email]],
        age: [null as number | null, [Validators.required]],
      })
    }, { validators: sameEmailValidator('parent.email', 'mentee.email') }),
    [ConsultationBooking.RelationshipWithMentee]: this.formBuilder.group({
      userType: [null as string | null, [Validators.required]],
      userTypeOther: [null as string | null, []],
      aboutYourself: [null as string | null, [Validators.required]],
    }),
    [ConsultationBooking.AdditionalInformation]: this.formBuilder.group({
      findUs: this.formBuilder.group({}),
      concern: this.formBuilder.group({}),
    }),
    [ConsultationBooking.ProgramAndAvailability]: this.formBuilder.group({
      mentoringProgram: this.formBuilder.group({}),
      mentoringSystem: this.formBuilder.group({}),
      smsConsent: [true, [Validators.required]],
    }),
  });

  @HostListener("window:beforeunload")
  canExit(): boolean {
    if (this.manualDeactivate) {
      return true;
    }
    return window.confirm('Changes you made may not be saved');
  }

  constructor(public router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private accountService: AccountService,
    private smartTypesService: SmartTypesService,
    private formBuilder: FormBuilder,
    private bookingService: BookingService,
    private referenceDataService: ReferenceDataService,
    private scroller: ViewportScroller,
    private changeDetectorRef: ChangeDetectorRef,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.url = router.url;
    this.subscriptions.push(router.events.subscribe(value => {
      if (value instanceof NavigationEnd) {
        this.url = value.url;
      }
    }));

    Object.values(ConsultationBooking).forEach(consultationBooking => {
      this.validSteps[parseInt(consultationBooking as string)] = false;
    });

    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
      }
    }));
  }

  ngOnInit(): void {
    this.fetchDefaultValues();

    const loaderIdentifier1 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier1).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier1);
      this.userTypeLookup = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.userTypes[smartCode.id] = smartCode;
      });
    }));
    const loaderIdentifier2 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.CountryCode, loaderIdentifier2).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier2);
      this.countryCodeLookup = smartCodes;
    }));
    this.subscriptions.push(this.bookingService.CurrentBookingService$.subscribe(service => {
      if (service) {
        this.meetingDuration = moment.duration(service.defaultDuration).asMinutes();
      }
    }));

    this.referenceDataService.fetchReferenceData();
  }

  fetchDefaultValues() {
    const date = this.route.snapshot.paramMap.get('date');
    const staffId = this.route.snapshot.paramMap.get('staffId');
    const firstName = this.route.snapshot.paramMap.get('firstName');
    const email = this.route.snapshot.paramMap.get('email');

    if (date && staffId && firstName && email) {
      this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.firstName.setValue(firstName);
      this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.email.setValue(email);
      this.preSelectAppointment = {
        date: new Date(Date.parse(date)),
        staffId: staffId
      };
    }
  }

  formGroup(consultationBooking: ConsultationBooking) {
    return this.form.get([consultationBooking]) as FormGroup;
  }

  userTypeChanged(smartCode: SmartCode | null) {
    if (smartCode?.code == 'MT') {
      this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.email.setValidators([Validators.email, Validators.required]);
    } else {
      this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.email.setValidators([Validators.email]);
    }

    this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.email.updateValueAndValidity();
  }

  next() {
    const currentPage = this.currentPage;
    const nextPage = this.currentPage + 1;
    if (Object.values(ConsultationBooking).includes(nextPage)) {
      this.validSteps[currentPage] = true;
      this.currentPage = nextPage;
      this.changeDetectorRef.detectChanges();
      this.scroller.scrollToPosition([0, 0]);
    } else {
      this.createAppointment();
    }

    if (!this.bookingAppointmentInitialized && this.currentPage == ConsultationBooking.AdditionalInformation) {
      this.accountService.initializeBookingAppointment();
    }
  }

  back() {
    const previousPage = this.currentPage - 1;
    if (Object.values(ConsultationBooking).includes(previousPage)) {
      this.currentPage = previousPage;
      this.changeDetectorRef.detectChanges();
      this.scroller.scrollToPosition([0, 0]);
    }
  }

  jump(step: ConsultationBooking) {
    if (this.validSteps[step] === true) {
      this.currentPage = step;
      this.changeDetectorRef.detectChanges();
      this.scroller.scrollToPosition([0, 0]);
    }
  }

  resetAppointmentSlot() {
    this.jump(this.Step.AppointmentDate);
    this.showAppointmentDateStep = false;
    this.form.controls[ConsultationBooking.AppointmentDate].controls.appointment.setValue(null);
    Object.values(ConsultationBooking).forEach(consultationBooking => {
      this.validSteps[parseInt(consultationBooking as string)] = false;
    });
    this.changeDetectorRef.detectChanges();
    this.showAppointmentDateStep = true;
  }

  createAppointment() {
    const menteeUserId = this.userTypeLookup.find(i => i.code === 'MT')?.id;
    const menteeEmail = this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.email.value != '' ? this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.email.value : null;

    if (!this.form.valid) {
      if ((this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.userType.value == menteeUserId) && menteeEmail == null) {
        toastr.error('Mentee Email is required when I am Mentee is selected.');
        this.jump(this.Step.MenteeDetails);
      } else {
        toastr.error('An error has occurred');
      }
      return;
    }

    const accountId = uuid();
    const concerns: Concerns[] = [];
    for (const key of Object.keys(this.form.controls[ConsultationBooking.AdditionalInformation].controls.concern.controls)) {
      const formGroup = this.form.controls[ConsultationBooking.AdditionalInformation].controls.concern.get(key);
      if (formGroup) {
        if (formGroup?.get('checkbox')?.value === true) {
          concerns.push({
            id: uuid(),
            accountId: accountId,
            smartCodeId: key,
            description: formGroup?.get('description')?.value
          });
        }
      }
    }

    const findUsSource: FindsUsSources[] = [];
    for (const key of Object.keys(this.form.controls[ConsultationBooking.AdditionalInformation].controls.findUs.controls)) {
      const formGroup = this.form.controls[ConsultationBooking.AdditionalInformation].controls.findUs.get(key);
      if (formGroup) {
        if (formGroup?.get('checkbox')?.value === true) {
          findUsSource.push({
            id: uuid(),
            accountId: accountId,
            smartCodeId: key,
            description: formGroup?.get('description')?.value
          });
        }
      }
    }

    const mentoringPrograms: MentoringProgram[] = [];
    for (const key of Object.keys(this.form.controls[ConsultationBooking.ProgramAndAvailability].controls.mentoringProgram.controls)) {
      const formGroup = this.form.controls[ConsultationBooking.ProgramAndAvailability].controls.mentoringProgram.get(key);
      if (formGroup) {
        if (formGroup?.get('checkbox')?.value === true) {
          mentoringPrograms.push({
            id: uuid(),
            accountId: accountId,
            smartCodeId: key
          });
        }
      }
    }

    const mentoringSystems: MentoringSystem[] = [];
    for (const key of Object.keys(this.form.controls[ConsultationBooking.ProgramAndAvailability].controls.mentoringSystem.controls)) {
      const formGroup = this.form.controls[ConsultationBooking.ProgramAndAvailability].controls.mentoringSystem.get(key);
      if (formGroup) {
        if (formGroup?.get('checkbox')?.value === true) {
          mentoringSystems.push({
            id: uuid(),
            accountId: accountId,
            smartCodeId: key
          });
        }
      }
    }

    const selectedAppointmentSlot = this.form.controls[ConsultationBooking.AppointmentDate].controls.appointment.value;
    if (selectedAppointmentSlot == null) {
      toastr.error('An error has occurred');
      return;
    }

    const endDateTime = new Date(selectedAppointmentSlot.date);
    endDateTime.setMinutes(endDateTime.getMinutes() + this.meetingDuration);

    if (menteeUserId == null) {
      toastr.error('An error has occurred');
      return;
    }

    const timeZone = this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.timeZone.value as string;

    const request: RegisterAccountRequest = {
      bookingAppointment: {
        startDateTime: {
          dateTime: adjustDateByTimeZone(selectedAppointmentSlot.date, timeZone, this.timeZones),
          timeZone: timeZone
        },
        endDateTime: {
          dateTime: adjustDateByTimeZone(endDateTime, timeZone, this.timeZones),
          timeZone: timeZone
        },
        serviceId: null,
        staffMemberIds: [selectedAppointmentSlot.staffId]
      },
      account: <Account>{
        id: accountId,
        creationDate: this.currentDate,
        accountStatus: AccountStatus.New,
        aboutYourself: this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.aboutYourself.value as string,
        smsConsent: this.form.controls[ConsultationBooking.ProgramAndAvailability].controls.smsConsent.value ?? false
      },
      mentee: <ExternalUser>{
        id: uuid(),
        creationDate: this.currentDate,
        firstName: this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.firstName.value as string,
        lastName: this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.lastName.value as string,
        emailAddress: menteeEmail,
        age: this.form.controls[ConsultationBooking.MenteeDetails].controls.mentee.controls.age.value ?? 0,
        userTypeId: menteeUserId,
        bookingCustomerId: ''
      },
      accountCreator: <ExternalUser>{
        id: uuid(),
        creationDate: this.currentDate,
        firstName: this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.firstName.value as string,
        lastName: this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.lastName.value as string,
        emailAddress: this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.email.value,
        timeZone: timeZone,
        countryCodeId: this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.countryCode.value != null ? this.countryCodeLookup.find(i => i.id == this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.countryCode.value)?.id : null,
        phoneNumber: this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.phoneNumber.value?.replace(/[^0-9]/g, ''),
        userTypeId: (this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.userType.value == menteeUserId) ? this.userTypeLookup.find(i => i.code === 'O')?.id : this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.userType.value,
        otherUserType: (this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.userType.value == menteeUserId) ? 'unknown' : this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.userTypeOther.value,
        receiveTexts: this.form.controls[ConsultationBooking.MenteeDetails].controls.parent.controls.receiveTexts.value ?? false,
        bookingCustomerId: ''
      },
      findsUsSources: findUsSource,
      concerns: concerns,
      mentoringPrograms: mentoringPrograms,
      mentoringSystems: mentoringSystems,
      isMenteeAccountCreator: (this.form.controls[ConsultationBooking.RelationshipWithMentee].controls.userType.value == menteeUserId)
    };

    const loaderIdentifier = uuid();
    this.subscriptions.push(this.accountService.registerAccount(request, loaderIdentifier).subscribe(result => {
      this.loaderService.hide(loaderIdentifier);
      if (!result.success) {
        if (result.error.status == HttpStatusCode.Conflict && result.error.error == 'Slot Unavailable') {
          toastr.error('Selected Appointment slot is no longer available please select again.');
          this.resetAppointmentSlot();
          return;
        }

        if (result.error.status == HttpStatusCode.BadRequest) {
          toastr.error(result.error.error);
          return;
        }

        toastr.error('Error creating appointment');
        return;
      }

      this.manualDeactivate = true;
      this.router.navigate(['/consultation-booked']);
    }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
