import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnDestroy, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BookingAppointmentSlot } from '@mya/booking-shared';
import { APP_CONFIG } from '@mya/configuration';
import { DateTimeService } from '../../../services/date-time.service';
import { Subscription as Subs } from 'rxjs';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'mya-appointment-date',
  templateUrl: './appointment-date.component.html',
  styleUrls: ['./appointment-date.component.scss'],
})
export class AppointmentDateComponent implements OnDestroy {
  @Input() form: FormGroup | null = null;
  @Input() preSelectAppointment: BookingAppointmentSlot | null = null;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  serviceId: string | null = null;
  overriddenDate: Date | null = null;
  subscriptions: Subs[] = [];

  get currentDate() {
    return this.overriddenDate != null ? this.overriddenDate : new Date();
  }

  constructor(@Inject(APP_CONFIG) appConfig: any,
    private scroller: ViewportScroller,
    private changeDetectorRef: ChangeDetectorRef,
    dateTimeService: DateTimeService) {
    this.subscriptions.push(dateTimeService.OverriddenDate$.subscribe(date => this.overriddenDate = date));
    this.serviceId = appConfig.booking.serviceId;
  }

  appointmentSlotSelected(date: BookingAppointmentSlot) {
    this.form?.get('appointment')?.setValue(date);
    this.changeDetectorRef.detectChanges();
    this.scroller.scrollToAnchor('next-btn');
  }

  previous() {
    this.back.emit();
  }

  submit() {
    this.next.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
