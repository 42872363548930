<!-- -------------------------------------------------------------- -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- -------------------------------------------------------------- -->
<div id="main-wrapper" class="h-100">
    <!-- -------------------------------------------------------------- -->
    <!-- Page wrapper  -->
    <!-- -------------------------------------------------------------- -->
    <div class="page-wrapper pt-0 m-0 h-100">
        <div class="container-fluid p-0 h-100">


            <div class="main-wrapper mya-login h-100">
                <div class="auth-wrapper background-img h-100">
                    <div class="row row-container">
                        <div class="col-md-8 col-12 d-flex justify-content-center align-items-center">
                            <div class="content-container">
                                <img src="./assets/images/mya-logo.png" class="light-logo d-block mx-auto mb-3"
                                    alt="homepage" style="width: 250px;" />
                                <h1 class="display-5 text-white text-center created-heading">
                                    <b>Appointment Created <br /> Successfully!</b>
                                </h1>
                                <h2 class="text-white text-center" style="text-wrap: balance;">Thanks for completing the
                                    consultation
                                    form, we will contact you as soon as possible.</h2>
                                <h3 class="text-white text-center mt-5 cursor-pointer" (click)="backToHome()">
                                    <u>Back to Home</u>
                                </h3>
                            </div>
                        </div>
                        <div
                            class="col-md-4 login-box d-md-flex d-none flex-column justify-content-center align-items-center px-5">
                            <img src="./assets/images/100-percent.png" alt="logo" />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <!-- -------------------------------------------------------------- -->
    <!-- End Page wrapper  -->
    <!-- -------------------------------------------------------------- -->
</div>
<!-- -------------------------------------------------------------- -->
<!-- End Wrapper -->
<!-- -------------------------------------------------------------- -->