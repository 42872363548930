import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConsultationBookingWizardComponent } from '../components/pages/consultation-booking-wizard/consultation-booking-wizard.component';
import { ConsultationBookedComponent } from '../components/pages/consultation-booked/consultation-booked.component';
import { DateCheckerComponent } from '../components/pages/date-checker/date-checker.component';
import { PendingChangesGuard } from '../guards/pending-changes.guard';

const routes: Routes = [
  {
    path: '',
    component: ConsultationBookingWizardComponent
  },
  { path: ':date/:staffId/:firstName/:email', component: ConsultationBookingWizardComponent, canDeactivate: [PendingChangesGuard] },
  {
    path: 'consultation-booked',
    component: ConsultationBookedComponent
  },
  { path: 'date-checker', component: DateCheckerComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LayoutRoutingModule { }
