<!-- -------------------------------------------------------------- -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- -------------------------------------------------------------- -->
<div id="main-wrapper" class="h-100">
    <!-- -------------------------------------------------------------- -->
    <!-- Topbar header - style you can find in pages.scss -->
    <!-- -------------------------------------------------------------- -->
    <header class="topbar d-block d-md-none">
        <nav class="navbar top-navbar navbar-expand-md navbar-light">
            <div class="navbar-header d-flex flex-column">
                <div class="w-100 d-flex flex-row-reverse flex-wrap logo-flex-wrap">
                    <a class="navbar-brand me-3" href="index.html">
                        <span class="logo-text py-0 ms-2 my-2">
                            <img src="./assets/images/mya-logo.png" class="light-logo d-block" alt="homepage" />
                        </span>
                    </a>

                    <a class="nav-link active ms-3 pt-0 pb-1 text-nowrap flex-grow-1" href="javascript:void(0)">
                        <span class="step me-2">
                            {{currentPage + 1}}
                        </span>
                        <b>{{ConsultationBookingLookup[currentPage]}}</b>
                    </a>
                </div>

                <div class="progress wizard-progress rounded-0 w-100">
                    <div class="progress-bar animated-progress-bar indeterminate"
                        [ngStyle]="{'width': (currentPage/(Step.ProgramAndAvailability + 1)) * 100 + '%'}"></div>
                </div>
            </div>
        </nav>
    </header>
    <!-- -------------------------------------------------------------- -->
    <!-- End Topbar header -->
    <!-- -------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------- -->
    <!-- Left Sidebar - style you can find in sidebar.scss  -->
    <!-- -------------------------------------------------------------- -->
    <aside class="left-sidebar d-md-block d-none">
        <!-- Sidebar scroll-->
        <div>
            <!-- Sidebar navigation-->
            <nav class="sidebar-nav">
                <img src="./assets/images/mya-logo.png" class="light-logo d-block mx-auto mb-5" alt="homepage" />

                <h1 class="text-white text-center">Schedule Your Consultation</h1>
                <div class="nav flex-column nav-pills ps-5 align-content-center" id="v-pills-tab" role="tablist"
                    aria-orientation="vertical">
                    <a class="nav-link" [ngClass]="{ 'active': currentPage === Step.AppointmentDate }" role="tab"
                        href="javascript:void(0)" (click)="jump(Step.AppointmentDate)">
                        <span class="step me-2">
                            <ng-container
                                *ngIf="validSteps[Step.AppointmentDate] === true && currentPage !== Step.AppointmentDate; else appointmentDate">
                                <i class="fas fa-check"></i>
                            </ng-container>
                            <ng-template #appointmentDate>
                                {{Step.AppointmentDate + 1}}
                            </ng-template>
                        </span>
                        <b>{{ConsultationBookingLookup[Step.AppointmentDate]}}</b>
                    </a>
                    <a class="nav-link" [ngClass]="{ 'active': currentPage === Step.MenteeDetails }" role="tab"
                        href="javascript:void(0)" (click)="jump(Step.MenteeDetails)">
                        <span class="step me-2">
                            <ng-container
                                *ngIf="validSteps[Step.MenteeDetails] === true && currentPage !== Step.MenteeDetails; else menteeDetails">
                                <i class="fas fa-check"></i>
                            </ng-container>
                            <ng-template #menteeDetails>
                                {{Step.MenteeDetails + 1}}
                            </ng-template>
                        </span>
                        <b> {{ConsultationBookingLookup[Step.MenteeDetails]}}</b>
                    </a>
                    <a class="nav-link" [ngClass]="{ 'active': currentPage === Step.RelationshipWithMentee }" role="tab"
                        href="javascript:void(0)" (click)="jump(Step.RelationshipWithMentee)">
                        <span class="step me-2">
                            <ng-container
                                *ngIf="validSteps[Step.RelationshipWithMentee] === true && currentPage !== Step.RelationshipWithMentee; else relationshipWithMentee">
                                <i class="fas fa-check"></i>
                            </ng-container>
                            <ng-template #relationshipWithMentee>
                                {{Step.RelationshipWithMentee + 1}}
                            </ng-template>
                        </span>
                        <b>{{ConsultationBookingLookup[Step.RelationshipWithMentee]}}</b>
                    </a>
                    <a class="nav-link" [ngClass]="{ 'active': currentPage === Step.AdditionalInformation }" role="tab"
                        href="javascript:void(0)" (click)="jump(Step.AdditionalInformation)">
                        <span class="step me-2">
                            <ng-container
                                *ngIf="validSteps[Step.AdditionalInformation] === true && currentPage !== Step.AdditionalInformation; else additionalInformation">
                                <i class="fas fa-check"></i>
                            </ng-container>
                            <ng-template #additionalInformation>
                                {{Step.AdditionalInformation + 1}}
                            </ng-template>
                        </span>
                        <b>{{ConsultationBookingLookup[Step.AdditionalInformation]}}</b>
                    </a>
                    <a class="nav-link" [ngClass]="{ 'active': currentPage === Step.ProgramAndAvailability }" role="tab"
                        href="javascript:void(0)" (click)="jump(Step.ProgramAndAvailability)">
                        <span class="step me-2">
                            <ng-container
                                *ngIf="validSteps[Step.ProgramAndAvailability] === true && currentPage !== Step.ProgramAndAvailability; else programAndAvailability">
                                <i class="fas fa-check"></i>
                            </ng-container>
                            <ng-template #programAndAvailability>
                                {{Step.ProgramAndAvailability + 1}}
                            </ng-template>
                        </span>
                        <b>{{ConsultationBookingLookup[Step.ProgramAndAvailability]}}</b>
                    </a>
                </div>
            </nav>
            <!-- End Sidebar navigation -->
        </div>
        <!-- End Sidebar scroll-->
    </aside>
    <!-- -------------------------------------------------------------- -->
    <!-- End Left Sidebar - style you can find in sidebar.scss  -->
    <!-- -------------------------------------------------------------- -->
    <!-- -------------------------------------------------------------- -->
    <!-- Page wrapper  -->
    <!-- -------------------------------------------------------------- -->
    <div class="page-wrapper pt-0 h-100">
        <div class="progress wizard-progress rounded-0 d-md-block d-none">
            <div class="progress-bar animated-progress-bar indeterminate h-100"
                [ngStyle]="{'width': (currentPage/(Step.ProgramAndAvailability + 1)) * 100 + '%'}"></div>
        </div>
        <div class="container-fluid pt-5 mya-appointments">
            <div class="row">
                <div class="tab-content">
                    <div class="tab-pane" [ngClass]="{ 'active': currentPage === Step.AppointmentDate }">
                        <mya-appointment-date *ngIf="showAppointmentDateStep" [form]="formGroup(Step.AppointmentDate)" (next)="next()" (back)="back()"
                            [preSelectAppointment]="preSelectAppointment"></mya-appointment-date>
                    </div>
                    <div class="tab-pane" [ngClass]="{ 'active': currentPage === Step.MenteeDetails}">
                        <mya-mentee-details [form]="formGroup(Step.MenteeDetails)" [appointmentSlot]="appointmentSlot"
                            (next)="next()" (back)="back()"></mya-mentee-details>
                    </div>
                    <div class="tab-pane" [ngClass]="{ 'active': currentPage === Step.RelationshipWithMentee}">
                        <mya-relationship-with-mentee [form]="formGroup(Step.RelationshipWithMentee)"
                            [appointmentSlot]="appointmentSlot" (next)="next()"
                            (back)="back()" (userTypeChange)="userTypeChanged($event)"></mya-relationship-with-mentee>
                    </div>
                    <div class="tab-pane" [ngClass]="{ 'active': currentPage === Step.AdditionalInformation}">
                        <mya-additional-information [form]="formGroup(Step.AdditionalInformation)"
                            [appointmentSlot]="appointmentSlot" (next)="next()"
                            (back)="back()"></mya-additional-information>
                    </div>
                    <div class="tab-pane" [ngClass]="{ 'active': currentPage === Step.ProgramAndAvailability}">
                        <mya-program-and-availability [form]="formGroup(Step.ProgramAndAvailability)"
                            [appointmentSlot]="appointmentSlot" (next)="next()"
                            (back)="back()"></mya-program-and-availability>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- -------------------------------------------------------------- -->
    <!-- End Page wrapper  -->
    <!-- -------------------------------------------------------------- -->
</div>
<!-- -------------------------------------------------------------- -->
<!-- End Wrapper -->
<!-- -------------------------------------------------------------- -->