import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SmartCode, SmartTypes } from '@mya/models';
import { SmartTypesService } from '../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { FormBuilder, FormGroup, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';
import { BookingAppointmentSlot } from '@mya/booking-shared';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-program-and-availability',
  templateUrl: './program-and-availability.component.html',
  styleUrls: ['./program-and-availability.component.scss'],
})
export class ProgramAndAvailabilityComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup | null = null;
  @Input() appointmentSlot: BookingAppointmentSlot | null = null;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  mentoringProgramLookup: SmartCode[] = [];
  mentoringSystemLookup: SmartCode[] = [];
  subscriptions: Subs[] = [];

  constructor(private smartTypesService: SmartTypesService, private loaderService: LoaderService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const loaderIdentifier1 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringProgram, loaderIdentifier1).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier1);
      this.generateControls(smartCodes, 'mentoringProgram');
      this.mentoringProgramLookup = smartCodes;
    }));

    const loaderIdentifier2 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.MentoringSystem, loaderIdentifier2).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier2);
      this.generateControls(smartCodes, 'mentoringSystem');
      this.mentoringSystemLookup = smartCodes;
    }));
  }

  generateControls(smartCodes: SmartCode[], formGroupName: string) {
    if (smartCodes.length > 0) {
      const formGroup = this.formBuilder.group({}, { validators: this.groupValidator(smartCodes) });

      for (const smartCode of smartCodes) {
        formGroup.addControl(smartCode.id, this.formBuilder.group({
          checkbox: new FormControl(false)
        }));
      }
      this.form?.setControl(formGroupName, formGroup, { emitEvent: false });
    }
  }

  private groupValidator(smartCodes: SmartCode[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      for (const smartCode of smartCodes) {
        if (control.get(smartCode.id)?.get('checkbox')?.value === true) {
          return null;
        }
      }

      return { required: true };
    }
  }

  previous() {
    this.back.emit();
  }

  submit() {
    this.form?.markAllAsTouched();

    if (this.form?.valid) {
      this.next.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
