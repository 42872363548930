import { Inject, Injectable, OnDestroy } from '@angular/core';
import { catchError, Observable } from 'rxjs';
import { RegisterAccountRequest } from '@mya/models';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { APP_CONFIG } from "@mya/configuration";
import { LoaderService } from './loader.service';
import { Subscription as Subs } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AccountService implements OnDestroy {
    baseApiUrl = '';
    subscriptions: Subs[] = [];

    constructor(private http: HttpClient, private loaderService: LoaderService,
        @Inject(APP_CONFIG) appConfig: any) {
        this.baseApiUrl = `${appConfig.apiUrls.account}api/account`
    }

    initializeBookingAppointment() {
        this.subscriptions.push(this.http.post(`${this.baseApiUrl}/initialize-booking-appointment`, {})
            .pipe(catchError((error: HttpErrorResponse) => {
                throw error;
            }))
            .subscribe(() => {
                console.log("booking appointment initalized");
            }));
    }

    registerAccount(request: RegisterAccountRequest, loaderIdentifier: string): Observable<any> {
        this.loaderService.show(loaderIdentifier);
        return new Observable(observer => {
            this.subscriptions.push(this.http.post(`${this.baseApiUrl}/register-account`, request)
                .pipe(catchError((error: HttpErrorResponse) => {
                    this.loaderService.hide(loaderIdentifier);
                    observer.next({ success: false, error: error });
                    throw error;
                }))
                .subscribe(() => {
                    observer.next({ success: true, errorType: null });
                }));
        });
    }

    ngOnDestroy(): void {
      this.subscriptions.forEach(s => s.unsubscribe());
    }
}