<div class="container-fluid px-0 h-100 mya-relationship-with-mentee">
    <div class="row h-100">
        <div class="col-12 mb-3">
            <div class="d-flex flex-wrap">
                <h1 class="me-2 flex-grow-1"><i class="fas fa-arrow-left cursor-pointer" (click)="previous()"></i> Relationship with the Mentee</h1>
                <div class="border border-2 date-title p-2" *ngIf="appointmentSlot">
                    <b>Selected:</b>
                    <i class="ri-calendar-event-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date: 'MMM, d, y'}}
                    </b>
                    <i class="ri-time-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date:'h:mm a'}}
                    </b>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 card-container">
            <div class="d-flex flex-column h-100">
                <div class="row" *ngIf="form">
                    <form [formGroup]="form" (ngSubmit)="submit()">

                        <div class="col-12">
                            <div class="p-4 form-box">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="my-2">
                                            <label for="username" class="form-label required mb-1"><b>Your Relationship
                                                    with
                                                    the Mentee</b></label>
                                            <ng-container *ngFor="let userType of userTypeLookup">
                                                <div class="form-check my-2">
                                                    <input class="form-check-input" type="radio" name="userType"
                                                        formControlName="userType" [id]="userType.id"
                                                        (change)="onUserTypeChanged(userType)" [value]="userType.id">
                                                    <label class="form-check-label" [for]="userType.id">
                                                        {{userType.code === 'MT' ? 'I am the Mentee' : userType.label}}
                                                    </label>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="form.get('userType')?.invalid && (form.get('userType')?.dirty || form.get('userType')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('userType')?.errors?.['required']}"
                                                    class="invalid-feedback">Please select one.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3" *ngIf="showOtherUserType">
                                        <label for="otherRelationInformation" class="form-label required">If other,
                                            please
                                            let us know your relationship to the Mentee</label>
                                        <input type="text" class="form-control mw-500" id="otherRelationInformation"
                                            formControlName="userTypeOther">
                                        <ng-container
                                            *ngIf="form.get('userTypeOther')?.invalid && (form.get('userTypeOther')?.dirty || form.get('userTypeOther')?.touched)">
                                            <div [ngClass]="{'d-block' : form.get('userTypeOther')?.errors?.['required']}"
                                                class="invalid-feedback">Relationship is required.</div>
                                        </ng-container>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                    <div class="col-12">
                                        <div class="my-2">
                                            <label for="about-yourself" class="form-label required mb-1"><b>Tell us a
                                                    bit
                                                    about your situation</b></label>
                                            <textarea id="about-yourself" class="form-control" rows="10"
                                                formControlName="aboutYourself"></textarea>
                                            <ng-container
                                                *ngIf="form.get('aboutYourself')?.invalid && (form.get('aboutYourself')?.dirty || form.get('aboutYourself')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('aboutYourself')?.errors?.['required']}"
                                                    class="invalid-feedback">Field is required.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-row-reverse">
                                <button type="submit" class="btn waves-effect waves-light btn-primary btn-next mt-4">
                                    Next<i class="fas fa-arrow-circle-right ms-1"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>


            </div>

        </div>
    </div>
</div>