import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SmartCode, SmartTypes } from '@mya/models';
import { SmartTypesService } from '../../../services/smart-types.service';
import { v4 as uuid } from 'uuid';
import { LoaderService } from '../../../services/loader.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BookingAppointmentSlot } from '@mya/booking-shared';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-relationship-with-mentee',
  templateUrl: './relationship-with-mentee.component.html',
  styleUrls: ['./relationship-with-mentee.component.scss'],
})
export class RelationshipWithMenteeComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup | null = null;
  @Input() appointmentSlot: BookingAppointmentSlot | null = null;
  @Output() userTypeChange = new EventEmitter<SmartCode | null>();
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  userTypeLookup: SmartCode[] = [];
  showOtherUserType = false;
  subscriptions: Subs[] = [];

  constructor(private smartTypesService: SmartTypesService,
    private loaderService: LoaderService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const loaderIdentifier = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.UserType, loaderIdentifier).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier);
      this.userTypeLookup = smartCodes;
    }));
  }

  onUserTypeChanged(smartCode: SmartCode) {
    if (this.form == null)
      return;

    this.showOtherUserType = smartCode.code === 'O';

    if (this.showOtherUserType) {
      this.form.get('userTypeOther')?.setValidators(Validators.required);
    } else {
      this.form.get('userTypeOther')?.removeValidators(Validators.required);
    }

    this.form.get('userTypeOther')?.updateValueAndValidity();

    this.userTypeChange.emit(smartCode);
  }

  previous() {
    this.back.emit();
  }

  submit() {
    this.form?.markAllAsTouched();

    if (this.form?.valid) {
      this.next.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
