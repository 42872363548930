export enum ConsultationBooking {
    AppointmentDate,
    MenteeDetails,
    RelationshipWithMentee,
    AdditionalInformation,
    ProgramAndAvailability
}

export class Steps {
    public static ConsultationBooking = ConsultationBooking;
}

export const ConsultationBookingLookup: Record<ConsultationBooking, string> = {
    [ConsultationBooking.AppointmentDate]: "Consultation Date",
    [ConsultationBooking.MenteeDetails]: "Mentee Details",
    [ConsultationBooking.RelationshipWithMentee]: "Relationship with the Mentee",
    [ConsultationBooking.AdditionalInformation]: "Additional Information",
    [ConsultationBooking.ProgramAndAvailability]: "Program and Availability",
};