import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { SmartCode, SmartTypes } from '@mya/models';
import { SmartTypesService } from '../../../services/smart-types.service';
import { LoaderService } from '../../../services/loader.service';
import { v4 as uuid } from 'uuid';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { BookingAppointmentSlot } from '@mya/booking-shared';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup | null = null;
  @Input() appointmentSlot: BookingAppointmentSlot | null = null;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  findUsLookup: SmartCode[] = [];
  concernLookup: SmartCode[] = [];
  showOtherFindUs = false;
  showFriendFindUs = false;
  showSearchFindUs = false;
  showSocialMediaFindUs = false;
  showOtherConcern = false;
  socialMediaOptions = ['Facebook', 'Instagram', 'Twitter', 'LinkedIn', 'YouTube']
  subscriptions: Subs[] = [];

  constructor(private smartTypesService: SmartTypesService,
    private loaderService: LoaderService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    const loaderIdentifier1 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.FindUS, loaderIdentifier1).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier1);
      this.generateControls(smartCodes, 'findUs');
      this.findUsLookup = smartCodes;
    }));

    const loaderIdentifier2 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.Concerns, loaderIdentifier2).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier2);
      this.generateControls(smartCodes, 'concern');
      this.concernLookup = smartCodes;
    }));
  }

  generateControls(smartCodes: SmartCode[], formGroupName: string) {
    if (smartCodes.length > 0) {
      const formGroup = this.formBuilder.group({}, { validators: this.groupValidator(smartCodes) });

      for (const smartCode of smartCodes) {
        formGroup.addControl(smartCode.id, this.formBuilder.group({
          checkbox: new FormControl(false),
          description: new FormControl(null as string | null)
        }));
      }
      this.form?.setControl(formGroupName, formGroup, { emitEvent: false });
    }
  }

  private groupValidator(smartCodes: SmartCode[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {

      for (const smartCode of smartCodes) {
        if (control.get(smartCode.id)?.get('checkbox')?.value === true) {
          return null;
        }
      }

      return { required: true };
    }
  }

  onFindUsChanged(event: any, smartCode: SmartCode) {
    if (this.form == null)
      return;

    switch (smartCode.code) {
      case 'OT':
        this.showOtherFindUs = event.target.checked;
        break;
      case 'FR':
        this.showFriendFindUs = event.target.checked;
        break;
      case 'SE':
        this.showSearchFindUs = event.target.checked;
        break;
      case 'SM':
        this.showSocialMediaFindUs = event.target.checked;
        break;
      default:
        break;
    }

    const descriptionControl = this.form.get(`findUs.${smartCode.id}.description`);
    if (event.target.checked) {
      descriptionControl?.setValidators(Validators.required);
    } else {
      descriptionControl?.removeValidators(Validators.required);
      descriptionControl?.updateValueAndValidity();
    }

    this.form.get('findUs')?.updateValueAndValidity();
  }

  onConcernChanged(event: any, smartCode: SmartCode) {
    if (this.form == null)
      return;

    if (smartCode.code === 'O') {
      this.showOtherConcern = event.target.checked;

      const descriptionControl = this.form.get(`concern.${smartCode.id}.description`);
      if (this.showOtherConcern) {
        descriptionControl?.setValidators(Validators.required);
      } else {
        descriptionControl?.removeValidators(Validators.required);
        descriptionControl?.updateValueAndValidity();
      }
    }
  }

  onSocialMediaChange(e: any, smartCode: SmartCode) {
    if (this.form == null)
      return;

    const descriptionControl = this.form.get(`findUs.${smartCode.id}.description`);
    descriptionControl?.setValue(e.target.value, {
      onlySelf: true,
    });
    descriptionControl?.updateValueAndValidity();
  }

  previous() {
    this.back.emit();
  }

  submit() {
    this.form?.markAllAsTouched();

    if (this.form?.valid) {
      this.next.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
