import { Component, Inject } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';

@Component({
  selector: 'mya-consultation-booked',
  templateUrl: './consultation-booked.component.html',
  styleUrls: ['./consultation-booked.component.scss'],
})
export class ConsultationBookedComponent {
  myaWebSiteUrl = '';

  constructor(@Inject(APP_CONFIG) appConfig: any) {
    this.myaWebSiteUrl = appConfig.myaWebsite;
  }

  backToHome() {
    window.location.href = this.myaWebSiteUrl;
  }
}
