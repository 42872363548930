import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { ReferenceDataTypes, ReferenceItem, SmartCode, SmartTypes } from '@mya/models';
import { v4 as uuid } from 'uuid';
import { SmartTypesService } from '../../../services/smart-types.service';
import { LoaderService } from '../../../services/loader.service';
import { ReferenceDataService } from '../../../services/reference-data.service';
import { BookingAppointmentSlot } from '@mya/booking-shared';
import { Subscription as Subs } from 'rxjs';

@Component({
  selector: 'mya-mentee-details',
  templateUrl: './mentee-details.component.html',
  styleUrls: ['./mentee-details.component.scss'],
})
export class MenteeDetailsComponent implements OnInit, OnDestroy {
  @Input() form: FormGroup | null = null;
  @Input() appointmentSlot: BookingAppointmentSlot | null = null;
  @Output() next = new EventEmitter();
  @Output() back = new EventEmitter();
  countryCodes: SmartCode[] = [];
  countryCodeLookup: Record<string, SmartCode> = {};
  timeZones: ReferenceItem[] = [];
  subscriptions: Subs[] = [];
  phoneMask: any = {
    mask: '(000) 000-0000'
  };
  ageMask = {
    mask: Number,
    min: 1,
    max: 99
  };

  get Validators() {
    return Validators;
  }

  constructor(private smartTypesService: SmartTypesService,
    private loaderService: LoaderService,
    private referenceDataService: ReferenceDataService,
    private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.subscriptions.push(this.referenceDataService.ReferenceData$.subscribe(referenceData => {
      if (referenceData != null) {
        const references = JSON.parse(referenceData);
        this.timeZones = references[ReferenceDataTypes.TimeZones];
        this.setCurrentTimeZone();
      }
    }));
    const loaderIdentifier1 = uuid();
    this.subscriptions.push(this.smartTypesService.SmartTypes(SmartTypes.CountryCode, loaderIdentifier1).subscribe(smartCodes => {
      this.loaderService.hide(loaderIdentifier1);
      this.countryCodes = smartCodes;
      smartCodes?.forEach(smartCode => {
        this.countryCodeLookup[smartCode.id] = smartCode;
      });

      const usCountryCode = this.countryCodes.find(i => i.code.toLowerCase() == 'us')?.id ?? null;
      if (this.form?.get('parent.countryCode')?.value == null && usCountryCode) {
        this.form?.get('parent.countryCode')?.setValue(usCountryCode);
        this.form?.get('parent.phoneNumber')?.setValidators([Validators.required, Validators.minLength(14)]);
        this.form?.get('parent.phoneNumber')?.updateValueAndValidity();
      }
    }));
  }

  changeTimeZone(e: any) {
    this.form?.get('parent.timeZone')?.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  changeCountryCode(countryCode: SmartCode) {
    this.form?.get('parent.countryCode')?.setValue(countryCode.id, {
      onlySelf: true,
    });

    if (countryCode.label == "1") {
      this.phoneMask = {
        mask: '(000) 000-0000'
      };
      this.form?.get('parent.phoneNumber')?.setValidators([Validators.required, Validators.minLength(14)]);
      this.form?.get('parent.phoneNumber')?.updateValueAndValidity();

    } else {
      this.phoneMask = {
        mask: '0000000000000000'
      };
      this.changeDetectorRef.detectChanges();
      if (this.form?.get('parent.phoneNumber')?.value) {
        this.form?.get('parent.phoneNumber')?.setValue(this.form?.get('parent.phoneNumber')?.value.replace(/[^0-9]/g, ''));
        this.form?.get('parent.phoneNumber')?.setValidators([Validators.required]);
        this.form?.get('parent.phoneNumber')?.updateValueAndValidity();
      }
    }
  }

  setCurrentTimeZone() {
    this.subscriptions.push(this.referenceDataService.getTimeZoneInfo(Intl.DateTimeFormat().resolvedOptions().timeZone).subscribe(timezoneInfo => {
      if (timezoneInfo && this.form?.get('parent.timeZone')?.value == null) {
        this.form?.get('parent.timeZone')?.setValue(timezoneInfo.name);
      }
    }));
  }

  previous() {
    this.back.emit();
  }

  submit() {
    this.form?.markAllAsTouched();

    if (this.form?.valid) {
      this.next.emit();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
