import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { APP_CONFIG } from '@mya/configuration';
import { GetSmartTypeByNameResult, SmartCode, SmartTypes } from '@mya/models';
import { BehaviorSubject, Observable, catchError } from 'rxjs';
import { LoaderService } from './loader.service';
import { Subscription as Subs } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SmartTypesService implements OnDestroy {
  private baseApiUrl = '';
  private _smartTypes: Record<string, BehaviorSubject<SmartCode[]>> = {};
  subscriptions: Subs[] = [];

  constructor(private http: HttpClient, @Inject(APP_CONFIG) appConfig: any, private loaderService: LoaderService) {
    this.baseApiUrl = appConfig.apiUrls.smartTypes + 'api/smartType';
  }

  public SmartTypes(smartType: SmartTypes, loaderIdentifier: string): Observable<SmartCode[]> {
    this.loaderService.show(loaderIdentifier);
    this.EnsureSmartType(smartType, loaderIdentifier);

    if (!this._smartTypes[smartType])
      this._smartTypes[smartType] = new BehaviorSubject<SmartCode[]>([]);

    return this._smartTypes[smartType];
  }

  private EnsureSmartType(smartType: SmartTypes, loaderIdentifier: string) {
    if (this._smartTypes[smartType])
      return;

    this.subscriptions.push(this.GetSmartType(smartType, loaderIdentifier).subscribe(smartTypeDto => {
      if (smartTypeDto == null || smartTypeDto.smartCodes == undefined)
        return;

      this._smartTypes[smartType].next(smartTypeDto.smartCodes);
    }));

  }

  private GetSmartType(smartType: SmartTypes, loaderIdentifier: string): Observable<GetSmartTypeByNameResult> {
    return this.http.get<GetSmartTypeByNameResult>(`${this.baseApiUrl}/${smartType}`)
      .pipe(catchError((error: HttpErrorResponse) => {
        this.loaderService.hide(loaderIdentifier);
        throw error;
      }));
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }
}
