<div class="container-fluid px-0 h-100 mya-program-and-availability">
    <div class="row h-100">
        <div class="col-12 mb-3">
            <div class="d-flex flex-wrap">
                <h1 class="me-2 flex-grow-1"><i class="fas fa-arrow-left cursor-pointer" (click)="previous()"></i>
                    Program and Availability</h1>
                <div class="border border-2 date-title p-2" *ngIf="appointmentSlot">
                    <b>Selected:</b>
                    <i class="ri-calendar-event-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date: 'MMM, d, y'}}
                    </b>
                    <i class="ri-time-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date:'h:mm a'}}
                    </b>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 card-container">
            <div class="d-flex flex-column h-100">
                <div class="row" *ngIf="form">
                    <form [formGroup]="form" (ngSubmit)="submit()">

                        <div class="col-12">
                            <div class="p-4 form-box">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="my-2">
                                            <label for="username" class="form-label required mb-1"><b>Choose your
                                                    Program</b></label>
                                            <div class="row" formGroupName="mentoringProgram">
                                                <ng-container *ngFor="let mentoringProgram of mentoringProgramLookup">
                                                    <div class="col-12" [formGroupName]="mentoringProgram.id">
                                                        <div class="form-check my-2">
                                                            <input class="form-check-input" type="checkbox"
                                                                [id]="mentoringProgram.id + '-checkbox'"
                                                                formControlName="checkbox">
                                                            <label class="form-check-label"
                                                                [for]="mentoringProgram.id + '-checkbox'">
                                                                {{mentoringProgram.label}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                </ng-container>
                                            </div>
                                            <ng-container
                                                *ngIf="form.get('mentoringProgram')?.invalid && (form.get('mentoringProgram')?.dirty || form.get('mentoringProgram')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('mentoringProgram')?.errors?.['required']}"
                                                    class="invalid-feedback">Please select one.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                    <div class="col-12">
                                        <label for="about-yourself" class="form-label required mb-1">
                                            <b>What would you like to start with?</b>
                                        </label>
                                        <div class="row" formGroupName="mentoringSystem">
                                            <ng-container *ngFor="let mentoringSystem of mentoringSystemLookup">
                                                <div class="col-12" [formGroupName]="mentoringSystem.id">
                                                    <div class="form-check my-2">
                                                        <input class="form-check-input" type="checkbox"
                                                            [id]="mentoringSystem.id + '-checkbox'"
                                                            formControlName="checkbox">
                                                        <label class="form-check-label"
                                                            [for]="mentoringSystem.id + '-checkbox'">
                                                            {{mentoringSystem.label}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </ng-container>
                                        </div>
                                        <ng-container
                                            *ngIf="form.get('mentoringSystem')?.invalid && (form.get('mentoringSystem')?.dirty || form.get('mentoringSystem')?.touched)">
                                            <div [ngClass]="{'d-block' : form.get('mentoringSystem')?.errors?.['required']}"
                                                class="invalid-feedback">Please select one.</div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-column flex-column-reverse flex-lg-row-reverse align-items-end">
                                <button type="submit" [disabled]="form.get('smsConsent')?.value !== true"
                                    class="btn waves-effect waves-light btn-primary btn-next mt-4 text-nowrap flex-shrink-0">Create Appointment
                                    <i class="fas fa-arrow-circle-right ms-1"></i>
                                </button>
                                <div class="form-check my-2 flex-grow-1">
                                    <input class="form-check-input" type="checkbox" id="smsConsent"
                                        formControlName="smsConsent">
                                    <label class="form-check-label" for="smsConsent">
                                        I agree to receive text messages (SMS) from WWYM Inc. for the purpose of confirmations and reminders.
                                    </label>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>


            </div>

        </div>
    </div>
</div>