<div class="container-fluid px-0 h-100 mya-mentee-details">
    <div class="row h-100">
        <div class="col-12 mb-3 p-0">
            <div class="d-flex flex-wrap">
                <h1 class="me-2 flex-grow-1"><i class="fas fa-arrow-left cursor-pointer" (click)="previous()"></i>
                    Mentee Details</h1>
                <div class="border border-2 date-title p-2" *ngIf="appointmentSlot">
                    <b>Selected:</b>
                    <i class="ri-calendar-event-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date: 'MMM, d, y'}}
                    </b>
                    <i class="ri-time-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date:'h:mm a'}}
                    </b>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 card-container">
            <div class="d-flex flex-column h-100">
                <div class="row" *ngIf="form">
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="col-12">
                            <div class="p-4 form-box">
                                <div class="row" formGroupName="parent">
                                    <div class="col-12">
                                        <h3>Parent / Guardian Information</h3>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="parent-firstName" class="form-label required mb-1">
                                                <b>First Name</b></label>
                                            <input type="text" id="parent-firstName" class="form-control"
                                             formControlName="firstName" />
                                            <ng-container
                                                *ngIf="form.get('parent.firstName')?.invalid && (form.get('parent.firstName')?.dirty || form.get('parent.firstName')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('parent.firstName')?.errors?.['required']}"
                                                    class="invalid-feedback">First Name is required.</div>
                                                <div [ngClass]="{'d-block' : form.get('parent.firstName')?.errors?.['minlength']}"
                                                    class="invalid-feedback">First Name must be at least 2 characters
                                                    long.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="parent-lastName" class="form-label required mb-1"><b>Last
                                                    Name</b></label>
                                            <input type="text" id="parent-lastName" class="form-control"
                                                formControlName="lastName" />
                                            <ng-container
                                                *ngIf="form.get('parent.lastName')?.invalid && (form.get('parent.lastName')?.dirty || form.get('parent.lastName')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('parent.lastName')?.errors?.['required']}"
                                                    class="invalid-feedback">Last Name is required.</div>
                                                <div [ngClass]="{'d-block' : form.get('parent.lastName')?.errors?.['minlength']}"
                                                    class="invalid-feedback">Last Name must be at least 2 characters
                                                    long.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="parent-email" class="form-label required mb-1"><b>Email
                                                    Address</b></label>
                                            <input type="email" id="parent-email" class="form-control"
                                                formControlName="email" />
                                            <ng-container
                                                *ngIf="form.get('parent.email')?.invalid && (form.get('parent.email')?.dirty || form.get('parent.email')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('parent.email')?.errors?.['required']}"
                                                    class="invalid-feedback">Email is required.</div>
                                                <div [ngClass]="{'d-block' : form.get('parent.email')?.errors?.['email']}"
                                                    class="invalid-feedback">Email is invalid.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="parent-time-zone" class="form-label required mb-1"><b>Time
                                                    Zone</b></label>
                                            <select id="parent-time-zone" class="form-select"
                                                (change)="changeTimeZone($event)" formControlName="timeZone">
                                                <option *ngFor="let timeZone of timeZones" [value]="timeZone.id">
                                                    {{ timeZone.description }}
                                                </option>
                                            </select>
                                            <ng-container
                                                *ngIf="form.get('parent.timeZone')?.invalid && (form.get('parent.timeZone')?.dirty || form.get('parent.timeZone')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('parent.timeZone')?.errors?.['required']}"
                                                    class="invalid-feedback">Time Zone is required.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="parent-phoneNumber" class="form-label required mb-1"><b>Phone
                                                    Number</b></label>
                                            <div class="input-group">
                                                <button
                                                    class="btn btn-light-light text-secondary font-medium dropdown-toggle country-dropdown"
                                                    type="button" data-bs-toggle="dropdown" aria-haspopup="true"
                                                    aria-expanded="false">
                                                    <img src="/static/images/blank.gif"
                                                        [class]="'flag fnone '+ countryCodeLookup[form.get('parent.countryCode')?.value]?.code?.toLowerCase()">
                                                </button>
                                                <ul class="dropdown-menu country-code-select" role="presentation"
                                                    style="margin-top: 0px; margin-bottom: 0px;">
                                                    <ng-container *ngFor="let countryCode of countryCodes">
                                                        <li *ngIf="countryCode.order === 2">
                                                            <hr class="dropdown-divider">
                                                        </li>
                                                        <li (click)="changeCountryCode(countryCode)">
                                                            <a role="option" class="dropdown-item" id="bs-select-1-1"
                                                                tabindex="0" aria-setsize="241" aria-posinset="2">
                                                                <span class="text">
                                                                    <img src="/static/images/blank.gif"
                                                                        [class]="'flag fnone '+ countryCode?.code?.toLowerCase()">
                                                                    <span>{{countryCode.description}}</span>
                                                                    <span class="text-muted ms-1">
                                                                        +{{countryCode.label}}
                                                                    </span>
                                                                </span>
                                                            </a>
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                                <input type="email" id="parent-phoneNumber" class="form-control"
                                                    [imask]="phoneMask" formControlName="phoneNumber" />
                                                <ng-container
                                                    *ngIf="form.get('parent.phoneNumber')?.invalid && (form.get('parent.phoneNumber')?.dirty || form.get('parent.phoneNumber')?.touched)">
                                                    <div [ngClass]="{'d-block' : form.get('parent.phoneNumber')?.errors?.['required']}"
                                                        class="invalid-feedback">Phone Number is required.</div>
                                                    <div [ngClass]="{'d-block' : form.get('parent.phoneNumber')?.errors?.['minlength']}"
                                                        class="invalid-feedback">Phone Number is invalid.
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-6 col-12">
                                        <div class="my-2">
                                            <label class="form-label required">
                                                Can Receive Texts</label>
                                            <div class="d-flex mt-2">
                                                <div class="form-check ms-2">
                                                    <input id="parent-receiveTexts-yes" class="form-check-input"
                                                        type="radio" formControlName="receiveTexts" [value]=true>
                                                    <label class="form-check-label" for="parent-receiveTexts-yes">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="form-check ms-2">
                                                    <input id="parent-receiveTexts-no" class="form-check-input"
                                                        type="radio" formControlName="receiveTexts" [value]=false>
                                                    <label class="form-check-label" for="parent-receiveTexts-no">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                </div>

                                <div class="row" formGroupName="mentee">

                                    <div class="col-12">
                                        <h3>Mentee Information</h3>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="mentee-firstName" class="form-label required mb-1"><b>First
                                                    Name</b></label>
                                            <input type="text" id="mentee-firstName" class="form-control"
                                                formControlName="firstName" />
                                            <ng-container
                                                *ngIf="form.get('mentee.firstName')?.invalid && (form.get('mentee.firstName')?.dirty || form.get('mentee.firstName')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('mentee.firstName')?.errors?.['required']}"
                                                    class="invalid-feedback">First Name is required.</div>
                                                <div [ngClass]="{'d-block' : form.get('mentee.firstName')?.errors?.['minlength']}"
                                                    class="invalid-feedback">First Name must be at least 2 characters
                                                    long.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="mentee-lastName" class="form-label mb-1"><b>Last
                                                    Name</b></label>
                                            <input type="text" id="mentee-lastName" class="form-control"
                                                formControlName="lastName" />
                                            <ng-container
                                                *ngIf="form.get('mentee.lastName')?.invalid && (form.get('mentee.lastName')?.dirty || form.get('mentee.lastName')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('mentee.lastName')?.errors?.['minlength']}"
                                                    class="invalid-feedback">Last Name must be at least 2 characters
                                                    long.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="mentee-email" class="form-label mb-1" [ngClass]="{ 'required': form.get('mentee.email')?.hasValidator(Validators.required)}"><b>Email
                                                    Address</b></label>
                                            <input type="email" id="mentee-email" class="form-control"
                                                formControlName="email" />
                                            <ng-container
                                                *ngIf="form.get('mentee.email')?.invalid && (form.get('mentee.email')?.dirty || form.get('mentee.email')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('mentee.email')?.errors?.['required']}"
                                                    class="invalid-feedback">Email is required.</div>
                                                <div [ngClass]="{'d-block' : form.get('mentee.email')?.errors?.['email']}"
                                                    class="invalid-feedback">Email is invalid.</div>
                                            </ng-container>
                                            <ng-container *ngIf="form.invalid && (form.dirty || form.touched)">
                                                <div [ngClass]="{'d-block' : form.errors?.['sameEmail']}"
                                                    class="invalid-feedback">Parent and Mentee Email cannot be same.
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="my-2">
                                            <label for="mentee-age" class="form-label required mb-1"><b>Age</b></label>
                                            <input type="number" id="mentee-age" class="form-control" [imask]="ageMask"
                                                formControlName="age" />
                                            <ng-container
                                                *ngIf="form.get('mentee.age')?.invalid && (form.get('mentee.age')?.dirty || form.get('mentee.age')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('mentee.age')?.errors?.['required']}"
                                                    class="invalid-feedback">Age is required.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-row-reverse">
                                <button type="submit" class="btn waves-effect waves-light btn-primary btn-next mt-4">
                                    Next<i class="fas fa-arrow-circle-right ms-1"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>


            </div>

        </div>
    </div>
</div>