<div class="container-fluid px-0 h-100 mya-additional-information">
    <div class="row h-100">
        <div class="col-12 mb-3">
            <div class="d-flex flex-wrap">
                <h1 class="me-2 flex-grow-1"><i class="fas fa-arrow-left cursor-pointer" (click)="previous()"></i> Additional Information</h1>
                <div class="border border-2 date-title p-2" *ngIf="appointmentSlot">
                    <b>Selected:</b>
                    <i class="ri-calendar-event-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date: 'MMM, d, y'}}
                    </b>
                    <i class="ri-time-line mx-2"></i>
                    <b>
                        {{appointmentSlot.date | date:'h:mm a'}}
                    </b>
                </div>
            </div>
        </div>
        <div class="col-12 p-0 card-container">
            <div class="d-flex flex-column h-100">
                <div class="row" *ngIf="form">
                    <form [formGroup]="form" (ngSubmit)="submit()">
                        <div class="col-12">
                            <div class="p-4 form-box">
                                <div class="row">
                                    <div class="col-12" formGroupName="findUs">
                                        <div class="my-2">
                                            <label for="username" class="form-label required mb-1"><b>How did you find
                                                    us?</b></label>
                                            <ng-container *ngFor="let findUs of findUsLookup">
                                                <div [formGroupName]="findUs.id">
                                                    <div class="form-check my-2">
                                                        <input class="form-check-input" type="checkbox"
                                                            [id]="findUs.id + '-checkbox'" formControlName="checkbox"
                                                            (change)="onFindUsChanged($event, findUs)">
                                                        <label class="form-check-label" [for]="findUs.id + '-checkbox'">
                                                            {{findUs.label}}
                                                        </label>
                                                    </div>
                                                    <ng-container *ngIf="showFriendFindUs && findUs.code === 'FR'">
                                                        <label [for]="findUs.id + '-description'"
                                                            class="form-label required">Enter Full Name</label>
                                                        <input type="text" class="form-control mw-500"
                                                            [id]="findUs.id + '-description'"
                                                            formControlName="description" />
                                                        <ng-container
                                                            *ngIf="form.get('findUs.'+ findUs.id + '.description')?.invalid && (form.get('findUs.'+ findUs.id + '.description')?.dirty || form.get('findUs.'+ findUs.id + '.description')?.touched)">
                                                            <div [ngClass]="{'d-block' : form.get('findUs.'+ findUs.id + '.description')?.errors?.['required']}"
                                                                class="invalid-feedback">Field is required.</div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="showSearchFindUs && findUs.code === 'SE'">
                                                        <label [for]="findUs.id + '-description'"
                                                            class="form-label required">Keywords used</label>
                                                        <input type="text" class="form-control mw-500" placeholder="Comma Separated"
                                                            [id]="findUs.id + '-description'"
                                                            formControlName="description" />
                                                        <ng-container
                                                            *ngIf="form.get('findUs.'+ findUs.id + '.description')?.invalid && (form.get('findUs.'+ findUs.id + '.description')?.dirty || form.get('findUs.'+ findUs.id + '.description')?.touched)">
                                                            <div [ngClass]="{'d-block' : form.get('findUs.'+ findUs.id + '.description')?.errors?.['required']}"
                                                                class="invalid-feedback">Field is required.</div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="showSocialMediaFindUs && findUs.code === 'SM'">
                                                        <label [for]="findUs.id + '-description'"
                                                            class="form-label required">Select Social Media
                                                            Platform</label>
                                                        <select class="form-select mw-500"
                                                            (change)="onSocialMediaChange($event,findUs)"
                                                            formControlName="description">
                                                            <ng-container
                                                                *ngFor="let socialMediaOption of socialMediaOptions">
                                                                <option [value]="socialMediaOption">
                                                                    {{ socialMediaOption }}
                                                                </option>
                                                            </ng-container>
                                                        </select>
                                                        <ng-container
                                                            *ngIf="form.get('findUs.'+ findUs.id + '.description')?.invalid && (form.get('findUs.'+ findUs.id + '.description')?.dirty || form.get('findUs.'+ findUs.id + '.description')?.touched)">
                                                            <div [ngClass]="{'d-block' : form.get('findUs.'+ findUs.id + '.description')?.errors?.['required']}"
                                                                class="invalid-feedback">Field is required.</div>
                                                        </ng-container>
                                                    </ng-container>
                                                    <ng-container *ngIf="showOtherFindUs && findUs.code === 'OT'">
                                                        <label [for]="findUs.id + '-description'"
                                                            class="form-label required">Tell us more</label>
                                                        <textarea class="form-control mw-500" rows="3"
                                                            [id]="findUs.id + '-description'"
                                                            formControlName="description">
                                                        </textarea>
                                                        <ng-container
                                                            *ngIf="form.get('findUs.'+ findUs.id + '.description')?.invalid && (form.get('findUs.'+ findUs.id + '.description')?.dirty || form.get('findUs.'+ findUs.id + '.description')?.touched)">
                                                            <div [ngClass]="{'d-block' : form.get('findUs.'+ findUs.id + '.description')?.errors?.['required']}"
                                                                class="invalid-feedback">Field is required.</div>
                                                        </ng-container>
                                                    </ng-container>
                                                </div>
                                            </ng-container>
                                            <ng-container
                                                *ngIf="form.get('findUs')?.invalid && (form.get('findUs')?.dirty || form.get('findUs')?.touched)">
                                                <div [ngClass]="{'d-block' : form.get('findUs')?.errors?.['required']}"
                                                    class="invalid-feedback">Please select one.</div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                    <label for="about-yourself" class="form-label required mb-1">
                                        <b>Areas of concern</b>
                                    </label>
                                    <div class="row" formGroupName="concern">
                                        <ng-container *ngFor="let concern of concernLookup">
                                            <div class="col-xl-4 col-lg-6 col-12" [formGroupName]="concern.id">
                                                <div class="form-check my-2">
                                                    <input class="form-check-input" type="checkbox"
                                                        [id]="concern.id + '-checkbox'" formControlName="checkbox"
                                                        (change)="onConcernChanged($event, concern)">
                                                    <label class="form-check-label" [for]="concern.id + '-checkbox'">
                                                        {{concern.label}}
                                                    </label>
                                                </div>
                                                <ng-container *ngIf="showOtherConcern && concern.code === 'O'">
                                                    <label [for]="concern.id + '-description'"
                                                        class="form-label required">Tell us more</label>
                                                    <textarea class="form-control mw-500" rows="3"
                                                        [id]="concern.id + '-description'"
                                                        formControlName="description">
                                                    </textarea>
                                                    <ng-container
                                                        *ngIf="form.get('concern.'+ concern.id + '.description')?.invalid && (form.get('concern.'+ concern.id + '.description')?.dirty || form.get('concern.'+ concern.id + '.description')?.touched)">
                                                        <div [ngClass]="{'d-block' : form.get('concern.'+ concern.id + '.description')?.errors?.['required']}"
                                                            class="invalid-feedback">Field is required.</div>
                                                    </ng-container>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                    </div>
                                    <ng-container
                                        *ngIf="form.get('concern')?.invalid && (form.get('concern')?.dirty || form.get('concern')?.touched)">
                                        <div [ngClass]="{'d-block' : form.get('concern')?.errors?.['required']}"
                                            class="invalid-feedback">Please select one.</div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="col-12">
                            <div class="d-flex flex-row-reverse">
                                <button type="submit" class="btn waves-effect waves-light btn-primary btn-next mt-4">
                                    Next<i class="fas fa-arrow-circle-right ms-1"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>


            </div>

        </div>
    </div>
</div>