import { Injectable } from '@angular/core';
declare const $: any;

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  identifiers: string[] = [];

  public show(identifier: string) {
    this.identifiers.push(identifier);

    $.blockUI({
      message:
        `<div class="custom-spinner">
        <svg class="loader" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 340 340">
          <circle cx="170" cy="170" r="140" stroke="var(--bs-primary)"/>
          <circle cx="170" cy="170" r="115" stroke="#404041"/>
          <circle cx="170" cy="170" r="90" stroke="var(--bs-primary)"/>
          <circle cx="170" cy="170" r="65" stroke="#404041"/>
          </svg>
        </div>`,
      css: {
        border: 0,
        padding: 0,
        backgroundColor: "transparent",
        'z-index': 100000
      },
    });
  }

  public hide(identifier: string) {
    const index: number = this.identifiers.findIndex(i => i == identifier);
    if (index !== -1) {
      this.identifiers.splice(index, 1);
    }

    if (this.identifiers.length == 0) {
      $.unblockUI();
    }
  }
}
