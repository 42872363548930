<div class="container-fluid px-0 h-100 mya-appointment-date">
    <div class="row h-100">
        <div class="col-12 mb-3 p-0">
            <h1>Choose Your<br> Consultation Date</h1>
        </div>
        <div class="col-12 p-0 card-container">
            <div class="d-flex flex-column h-100">
                <div class="row" *ngIf="form">

                    <div class="col-12">
                        <mya-appointment-datepicker *ngIf="serviceId && currentDate" [serviceId]="serviceId"
                            [currentDate]="currentDate" [preSelectAppointment]="preSelectAppointment"
                            (appointmentSlotSelected)="appointmentSlotSelected($event)">
                        </mya-appointment-datepicker>
                    </div>
                    <div class="col-12">
                        <div class="d-flex flex-wrap mt-3 selected-date-container"
                            *ngIf="form.get('appointment')?.valid">
                            <div class="d-flex align-items-center me-3">
                                <i class="ri-calendar-event-line text-gray selected-icon"></i>
                                <span class="ms-1 text-gray selected-text">Selected Date:</span>
                                <span class="ms-1 selected-subtext"><b>
                                        {{form.get('appointment')?.value.date | date: 'MMM, d, y'}}</b></span>
                            </div>
                            <div class="d-flex align-items-center">
                                <i class="ri-time-line text-gray selected-icon"></i>
                                <span class="ms-1 text-gray selected-text">Selected Time:</span>
                                <span class="ms-1 selected-subtext">
                                    <b>{{form.get('appointment')?.value.date | date:'h:mm a'}}</b></span>
                            </div>
                        </div>
                    </div>

                    <div class="col-12" *ngIf="form.valid">
                        <div class="d-flex flex-row-reverse">
                            <button id="next-btn" type="button" class="btn waves-effect waves-light btn-primary btn-next mt-4"
                                (click)="submit()">Next<i class="fas fa-arrow-circle-right ms-1"></i>
                            </button>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>