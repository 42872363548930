import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutRoutingModule } from './layout-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IMaskModule } from 'angular-imask';
import { BookingSharedModule } from '@mya/booking-shared';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { ConsultationBookingWizardComponent } from '../components/pages/consultation-booking-wizard/consultation-booking-wizard.component';
import { AdditionalInformationComponent } from '../components/shared/additional-information/additional-information.component';
import { AppointmentDateComponent } from '../components/shared/appointment-date/appointment-date.component';
import { MenteeDetailsComponent } from '../components/shared/mentee-details/mentee-details.component';
import { ProgramAndAvailabilityComponent } from '../components/shared/program-and-availability/program-and-availability.component';
import { RelationshipWithMenteeComponent } from '../components/shared/relationship-with-mentee/relationship-with-mentee.component';

@NgModule({
  declarations: [
    ConsultationBookingWizardComponent,
    AppointmentDateComponent,
    MenteeDetailsComponent,
    RelationshipWithMenteeComponent,
    AdditionalInformationComponent,
    ProgramAndAvailabilityComponent],
  imports: [
    CommonModule,
    LayoutRoutingModule,
    BookingSharedModule,
    ReactiveFormsModule,
    IMaskModule,
    AngularEditorModule,
    FormsModule
  ]
})
export class LayoutModule { }
